import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import {
  NavMenuWrapper,
  Title,
  NavList,
  NavMenuSticky,
  CloseBurgerButton,
  Header,
  ClickCapture,
} from "./styles/NavMenuStyle";
import { variantsBurger, variantsClickCapture } from "./utils/animations";
import NavItem from "./NavItem";
import createNavTree from "./utils/createNavTree";
import closeArrow from "../../assets/images/icons/arrow_right.svg";

const NavMenu = ({
  setBurger,
  burgerIsOpen,
  headerHeight,
  pageContext,
  content,
}) => {
  const { allMdx } = useStaticQuery(
    graphql`
      {
        allMdx(sort: { fields: frontmatter___order, order: ASC }) {
          edges {
            node {
              frontmatter {
                name
                path
                subtitle
                title
                parent
                order
                ico {
                  publicURL
                }
              }
            }
          }
        }
      }
    `
  );
  const navTree = createNavTree(allMdx.edges);
  return (
    <>
      <ClickCapture
        onClick={() => setBurger(false)}
        initial={false}
        headerHeight={headerHeight}
        animate={burgerIsOpen ? "open" : "closed"}
        variants={variantsClickCapture}
      />

      <NavMenuWrapper
        burgerIsOpen={burgerIsOpen}
        initial={false}
        headerHeight={headerHeight}
        animate={burgerIsOpen ? "open" : "closed"}
        variants={variantsBurger}
      >
        <NavMenuSticky headerHeight={headerHeight}>
          <Header>
            <Title>{content.title}</Title>
            <CloseBurgerButton onClick={() => setBurger(false)}>
              <img src={closeArrow} alt="close" />
            </CloseBurgerButton>
          </Header>
          <NavList>
            {navTree.map((item) => (
              <NavItem key={item.name} data={item} pageContext={pageContext}>
                {item.name}
              </NavItem>
            ))}
          </NavList>
        </NavMenuSticky>
      </NavMenuWrapper>
    </>
  );
};

export default NavMenu;
