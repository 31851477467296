import homeIco from "../../../assets/images/icons/home.svg";
const createNavTree = (data) => {
  const navTree = [
    {
      name: "home",
      to: "/",
      ico: homeIco,
      subItems: [],
      order: 0,
    },
  ];
  data.forEach((element) => {
    const { frontmatter } = element.node;
    if (frontmatter.parent === null) {
      navTree.push({
        name: frontmatter.name,
        to: frontmatter.path,
        ico: frontmatter.ico.publicURL,
        subItems: [],
        order: frontmatter.order,
        isOpen: false,
      });
    }
  });
  data.forEach((element) => {
    const { frontmatter } = element.node;
    if (frontmatter.parent !== null) {
      navTree.forEach((item, index) => {
        if (item.name === frontmatter.parent) {
          navTree[index].subItems.push({
            name: frontmatter.name,
            to: frontmatter.path,
          });
        }
      });
    }
  });
  // navTree.sort(({ order: a }, { order: b }) => a - b);
  return navTree;
};

export default createNavTree;
