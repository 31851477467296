export const variantsBurger = {
  open: {
    display: "initial",
    opacity: 1,
    x: 0,
    transition: { duration: 0.3, type: "tween" },
  },
  closed: {
    opacity: 0,
    x: "100%",
    transition: { duration: 0.3, type: "tween" },
    transitionEnd: { display: "none" },
  },
};

export const variantsClickCapture = {
  open: {
    opacity: 1,
    transition: { duration: 0.3, type: "tween" },
    display: "initial",
  },
  closed: {
    opacity: 0,
    transition: { duration: 0.3, type: "tween" },
    transitionEnd: { display: "none" },
  },
};
