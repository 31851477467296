import React, { useState, useEffect } from "react";
import styled from "styled-components";

const CookiesStyledWrapper = styled.div`
  position: fixed;
  bottom: 0;
  color: rgb(51, 51, 51);
  background-color: rgb(238, 238, 238);
  text-align: center;
  width: 100%;
  padding: 12px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  z-index: 99;
`;

const ButtonClose = styled.button`
  background-color: rgb(241, 214, 0);
  padding: 8px;
  min-width: 100px;
  border-radius: 8px;
  float: right;
  @media (max-width: 975px) {
    float: none;
    margin: 5px auto;
  }
`;

const CookiesInfo = ({ content }) => {
  const [isHidden, setHidden] = useState(true);

  const handleClick = () => {
    setHidden(true);
    try {
      localStorage.setItem("acceptCookies", JSON.stringify(true));
    } catch (error) {}
  };
  useEffect(() => {
    let localValue = false;
    try {
      localValue = !!JSON.parse(localStorage.getItem("acceptCookies"));
    } catch (error) {}
    setHidden(localValue);
  }, [setHidden]);
  return isHidden ? null : (
    <CookiesStyledWrapper>
      <span style={{ margin: "0 auto" }}>
        {content.textInfo}{" "}
        <a href={content.url} target="_blank" rel="noreferrer">
          {content.urlText}
        </a>
      </span>
      <ButtonClose onClick={handleClick}>{content.agreeButton}</ButtonClose>
    </CookiesStyledWrapper>
  );
};

export default CookiesInfo;
