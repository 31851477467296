import styled from "styled-components";
import { motion } from "framer-motion";

export const NavMenuWrapper = styled(motion.aside)`
  border-right: 1.5px solid ${({ theme }) => theme.colors.separator};
  background-color: ${({ theme }) => theme.colors.backgroundSecond};
  flex: 0 0 25%;
  display: flex;
  flex-direction: column;
  position: relative;
  @media (min-width: 1200px) {
    display: block !important;
    opacity: 1 !important;
    transform: initial !important;
  }

  @media (max-width: 1200px) {
    border-left: 1.5px solid ${({ theme }) => theme.colors.separator};
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    z-index: 2;
    padding: 0 10%;
  }
`;
export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.text};
  font-size: 22px;
`;
export const NavList = styled.ul`
  @media (max-width: 1200px) {
    overflow-y: auto;
    overflow-x: hidden;
    /* margin-right: 24px; */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
      border-radius: 5px;
    }

    ::-webkit-scrollbar {
      width: 3px;
      background-color: rgba(0, 0, 0, 0.1);
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: rgba(0, 0, 0, 0.3);
    }
    max-height: calc(100vh - 150px);
    min-height: 50vh;
  }
`;

export const NavMenuSticky = styled.div`
  width: 50%;
  max-width: 220px;
  margin: 56px 48px 0 auto;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

export const CloseBurgerButton = styled.button`
  height: min-content;
  filter: invert(${({ theme }) => theme.filters.invert})
    grayscale(${({ theme }) => theme.filters.invert});
  @media (min-width: 1200px) {
    display: none;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
  @media (max-width: 1200px) {
    margin-bottom: 48px;
  }
`;

export const ClickCapture = styled(motion.div)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6);
  @media (min-width: 1200px) {
    display: none !important;
  }
`;
