import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const TextStyled = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ textFormat }) => textFormat.fontSizeMobile};
  font-weight: ${({ textFormat }) => textFormat.fontWeight};
  @media (min-width: 700px) {
    font-size: ${({ textFormat }) => textFormat.fontSizeDesktop};
  }
`;

const Text = ({ children, textFormat, style, className }) => {
  const textData = (textFormat) => {
    switch (textFormat) {
      case "title":
        return {
          fontSizeMobile: "20px",
          fontSizeDesktop: "24px",
          fontWeight: 700,
        };
      case "subtitle":
        return {
          fontSizeMobile: "17px",
          fontSizeDesktop: "18px",
          fontWeight: 500,
        };
      case "text":
        return {
          fontSizeMobile: "16px",
          fontSizeDesktop: "16px",
          fontWeight: 400,
        };
      default:
        break;
    }
  };
  return (
    <TextStyled
      className={className}
      style={style}
      textFormat={textData(textFormat)}
    >
      {children}
    </TextStyled>
  );
};

Text.defaultProps = {
  textFormat: "text",
  style: {},
  className: null,
};

Text.propTypes = {
  textFormat: PropTypes.oneOf(["title", "subtitle", "text"]).isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
};

export default Text;
