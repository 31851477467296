const bright = {
  title: "bright",

  colors: {
    background: "#fff",
    backgroundSecond: "#FAFCFD",
    text: "#1D2746",
    contrast: "rgba(0,0,0, 0.2)",
    textHighlight: "#10B3D6",
    separator: "rgba(196, 196, 196, 0.4)",
    backgroundFocus: "#cce2ff",
  },
  filters: {
    invert: "0%",
  },
};
export default bright;
