import React, { useState } from "react";

import {
  NavItemWrapper,
  Thumbnail,
  Carrot,
  SubMenu,
  SubItem,
  LinkStyled,
} from "./styles/NavItemStyle";
import downArrowIco from "../../assets/images/icons/down_arrow.svg";

const NavItem = ({ children, data, pageContext }) => {
  let currentOpenItemName = false;
  try {
    currentOpenItemName = pageContext.breadcrumb.crumbs[1].crumbLabel;
  } catch (error) {
    currentOpenItemName = false;
  }
  const [isOpen, setOpen] = useState(currentOpenItemName === data.name);
  return (
    <NavItemWrapper>
      <LinkStyled to={data.to}>
        <Thumbnail src={data.ico} alt={data.name} />
        {children}
      </LinkStyled>
      {data.subItems.length > 0 ? (
        <>
          <Carrot
            onClick={() => {
              setOpen(!isOpen);
            }}
            isOpen={isOpen}
          >
            <img alt="drop down" src={downArrowIco} />
          </Carrot>
        </>
      ) : null}
      <SubMenu isOpen={isOpen}>
        {data.subItems.map((item) => (
          <SubItem key={item.name}>
            <LinkStyled to={item.to}>{item.name}</LinkStyled>
          </SubItem>
        ))}
      </SubMenu>
    </NavItemWrapper>
  );
};

export default NavItem;
