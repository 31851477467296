import React, { useContext } from "react";
import styled from "styled-components";

import { GlobalStateContext } from "../../context/GlobalContextProvider";

import sun from "../../assets/images/icons/sun.svg";
import moon from "../../assets/images/icons/moon.svg";

const ModeSwitcherWrapper = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.text};
  border-radius: 50px;
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;
  width: 48px;
  height: 24px;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  @media (min-width: 700px) {
    width: 70px;
    height: 32px;
  }
`;

const SwitcherIco = styled.img`
  width: 15px;
  margin: 3px;
  user-select: none;
  transition: 0.5s transform;
  :first-child {
    transform: ${({ toggle }) => (toggle ? "scale(0)" : "scale(1)")};
  }
  :nth-child(2) {
    transform: ${({ toggle }) => (toggle ? "scale(1)" : "scale(0)")};
  }
  @media (min-width: 700px) {
    width: 25px;
  }
`;

const SwitcherCircle = styled.div`
  width: 17px;
  height: 17px;
  border: 2px solid ${({ theme }) => theme.colors.text};
  position: absolute;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.background};
  left: ${({ toggle }) => (toggle ? "25%" : "75%")};
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s left;
  @media (min-width: 700px) {
    width: 24px;
    height: 24px;
  }
`;

const ModeSwitcher = (props) => {
  const [state, dispatch] = useContext(GlobalStateContext);
  return (
    <button
      onClick={() => dispatch({ type: "CHANGE_THEME", value: !state.darkMode })}
      style={props.style}
    >
      <ModeSwitcherWrapper htmlFor="modeSwitcher">
        <SwitcherIco
          draggable="false"
          src={sun}
          alt="sun"
          toggle={state.darkMode}
        />
        <SwitcherIco
          draggable="false"
          src={moon}
          alt="moon"
          toggle={state.darkMode}
        />
        <SwitcherCircle toggle={state.darkMode} />
      </ModeSwitcherWrapper>
    </button>
  );
};

export default ModeSwitcher;
