import discordIco from "./assets/images/icons/discord.svg";
import youtubeIco from "./assets/images/icons/youtube.svg";
import logo from "./assets/images/logo.svg";
import ogImage from "./assets/images/OgImage.jpg";

const content = {
  header: {
    title: "ARK TESTED",
    subtitle: "ARK Survival Evolved knowledge base",
    logo: logo,
  },
  cookies: {
    textInfo:
      "We use cookies to enhance your experience. By continuing to visit this site you agree to our use of cookies.",
    urlText: "Read more",
    url: "/privacypolicy",
    agreeButton: "Accept cookies",
  },
  footer: {
    copyright: `Copyright © 2021, Ark Tested and/or its subsidiaries or
    affiliates. Game content and materials are trademarks and
    copyrights of their respective publisher and its licensors. All
    Rights Reserved.`,
    aside: [
      {
        title: "Questions & suggestions?",
        text: "Join our Discord! Experienced ARK players will help you with anything!",
      },
      {
        title: "ARK Video Tutorials",
        text: "Check ARK Tested channel on Youtube and get to know everything about ARK!",
      },
    ],
    links: [
      {
        title: "QUICK LINKS",
        list: [
          { name: "Partners", url: "/partners" },
          {
            name: "Buy me a coffee",
            url: "https://www.youtube.com/channel/UCJpeA4k6RUbVzRPfCXyXeRw/join",
          },
        ],
      },
      {
        title: "ABOUT",
        list: [
          { name: "Contact", url: "/contact" },
          { name: "Privacy Policy", url: "/privacypolicy" },
        ],
      },
    ],
    social: [
      {
        name: "discord",
        ico: discordIco,
        url: "https://discord.gg/2Sb7HrY6Bk",
      },
      {
        name: "youtube",
        ico: youtubeIco,
        url: "https://www.youtube.com/channel/UCJpeA4k6RUbVzRPfCXyXeRw",
      },
    ],
  },
  navMenu: {
    title: "Menu",
    communityText: "Join the best community!",
    communityUrls: [
      { name: "discord", url: "https://discord.com/", ico: discordIco },
      { name: "youtube", url: "https://youtube.com/", ico: youtubeIco },
    ],
  },
  website: {
    ogImage: ogImage,
  },
};
export default content;
