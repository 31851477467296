import React, { useState, useContext } from "react";
import styled from "styled-components";
import { ThemeProvider } from "styled-components";

import bright from "../styles/themes/bright";
import dark from "../styles/themes/dark";
import { GlobalStateContext } from "../context/GlobalContextProvider";
import Header from "../components/Header";
import Breadcrumb from "../components/Breadcrumb";
// import NavMenu from "../components/NavMenu/NavMenu";
import NavMenu from "../components/NavMenuV2/NavMenu";
import RightSideBar from "../components/RightSideBar";
import Footer from "../components/Footer";
import CookiesInfo from "../components/CookiesInfo";
import content from "../content";

const LayoutStyled = styled.div`
  min-height: 100vh;
  position: relative;
  background: ${({ theme }) =>
    `linear-gradient(90deg, ${theme.colors.backgroundSecond} 50%, ${theme.colors.background} 50%)`};
`;
const ContentContainer = styled.div`
  @media (min-width: 1200px) {
    max-width: 850px;
  }
  flex: auto;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.colors.background};
`;
const Content = styled.div`
  max-width: 850px;
  margin: 0 auto;
  h2 {
    font-size: 32px;
    margin: 80px 0 24px 0;
    font-weight: 900;
  }
  h3 {
    font-size: 24px;
    margin: 48px 0 24px 0;
    font-weight: 900;
  }
  iframe {
    max-width: 100%;
    margin: 24px auto;
    display: block;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;

const Layout = ({ children, pageContext }) => {
  const [burgerIsOpen, setBurger] = useState(false);
  const [headerHeight, setHeaderHeight] = useState();
  const [state] = useContext(GlobalStateContext);
  return (
    <>
      <ThemeProvider theme={state.darkMode ? dark : bright}>
        <LayoutStyled>
          <Header
            setBurger={setBurger}
            setHeaderHeight={setHeaderHeight}
            content={content.header}
          />
          <Container>
            <NavMenu
              burgerIsOpen={burgerIsOpen}
              setBurger={setBurger}
              headerHeight={headerHeight}
              pageContext={pageContext}
              content={content.navMenu}
            />
            <ContentContainer>
              <Breadcrumb pageContext={pageContext} />
              <Content>{children}</Content>
            </ContentContainer>
            <RightSideBar />
          </Container>
          <Footer content={content.footer} />
        </LayoutStyled>
        <CookiesInfo content={content.cookies} />
      </ThemeProvider>
    </>
  );
};

export default Layout;
