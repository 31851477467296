import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import PropTypes from "prop-types";
import Text from "./Text/Text";

import timeIco from "../assets/images/icons/time.svg";
import arrow from "../assets/images/icons/down_arrow.svg";

const BreadcrumbStyled = styled.div`
  height: 55px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 24px;
  background-color: ${({ theme }) => theme.colors.backgroundSecond};
  justify-content: space-between;
  border-bottom: 2px solid ${({ theme }) => theme.colors.separator};
  @media (min-width: 700px) {
    padding: 0 36px;
  }
`;

const BreadcrumbList = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-transform: capitalize;
`;

const BreadcrumbElement = styled.li`
  display: flex;
  align-items: center;
  img {
    filter: invert(${({ theme }) => theme.filters.invert})
      grayscale(${({ theme }) => theme.filters.invert});
  }
`;

const Date = styled.div`
  display: none;
  @media (min-width: 1000px) {
    display: flex;
  }
  img {
    margin: 0 12px;
    filter: invert(${({ theme }) => theme.filters.invert})
      grayscale(${({ theme }) => theme.filters.invert});
  }
`;

const Breadcrumb = ({ pageContext }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext;
  const {
    currentBuildDate: { currentDate },
  } = useStaticQuery(graphql`
    query {
      currentBuildDate {
        currentDate
      }
    }
  `);

  return (
    <BreadcrumbStyled>
      <BreadcrumbList>
        {crumbs.map(({ pathname, crumbLabel }, index) => (
          <BreadcrumbElement key={pathname}>
            <Link to={pathname}>
              <Text>{crumbLabel.toLowerCase().replace("-", " ")}</Text>
            </Link>
            {crumbs.length - 1 > index ? (
              <img
                src={arrow}
                alt=">"
                style={{ transform: "rotate(-90deg)" }}
              />
            ) : null}
          </BreadcrumbElement>
        ))}
      </BreadcrumbList>
      <Date>
        <img src={timeIco} alt="time" />
        <Text>Updated on {currentDate}</Text>
      </Date>
    </BreadcrumbStyled>
  );
};

Breadcrumb.defaultProps = {
  pageContext: {
    id: "",
    breadcrumb: {
      location: "",
      crumbs: [{ pathname: "/", crumbLabel: "Home" }],
    },
  },
};

Breadcrumb.propTypes = {
  path: PropTypes.object,
};

export default Breadcrumb;
