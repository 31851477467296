import React, { useContext, useRef, useEffect } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import Text from "./Text/Text";
import ModeSwitcher from "./Buttons/ModeSwitcher";
import { GlobalStateContext } from "../context/GlobalContextProvider";

import burgerIco from "../assets/images/icons/burger.svg";

const HeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.contrast};
  background-color: ${({ theme }) => theme.colors.background};
  box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.3);
`;

const HeaderStyled = styled.div`
  max-width: 1920px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  @media (min-width: 700px) {
    padding: 20px 8%;
  }
  @media (max-width: 900px) {
    padding: 20px 3%;
  }
  @media (max-width: 700px) {
    padding: 5px 20px;
  }
`;

const Logo = styled.img`
  width: 48px;
  @media (min-width: 700px) {
    width: 50px;
  }
`;

const HeaderTextWrapper = styled(Link)`
  margin-left: 16px;
  display: none;
  @media (min-width: 700px) {
    display: initial;
  }
`;

const Burger = styled.button`
  filter: invert(${({ theme }) => theme.filters.invert})
    grayscale(${({ theme }) => theme.filters.invert});
  margin-left: 36px;
  @media (min-width: 1200px) {
    display: none;
  }
`;

const Header = ({ setBurger, setHeaderHeight, content }) => {
  const [state] = useContext(GlobalStateContext);
  const headerRef = useRef();
  useEffect(() => {
    setHeaderHeight(headerRef.current.offsetHeight);
  }, [setHeaderHeight]);
  return (
    <HeaderWrapper darkMode={state.darkMode} ref={headerRef}>
      <HeaderStyled>
        <Link to="/">
          <Logo src={content.logo} alt={content.title} />
        </Link>
        <HeaderTextWrapper to="/">
          <Text textFormat="title">{content.title}</Text>
          <Text>{content.subtitle}</Text>
        </HeaderTextWrapper>
        <ModeSwitcher style={{ marginLeft: "auto" }} />
        <Burger onClick={() => setBurger(true)}>
          <img src={burgerIco} alt="menu" />
        </Burger>
      </HeaderStyled>
    </HeaderWrapper>
  );
};

export default Header;
