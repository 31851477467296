import React from "react";
import styled from "styled-components";

import GoogleAds from "./GoogleAds";

const BarWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  flex-wrap: wrap;
  max-height: 750px;
  width: 100%;
  border-top: 1.5px solid ${({ theme }) => theme.colors.separator};
  @media (min-width: 1200px) {
    flex: 0 0 25%;
    width: 400px;
    max-height: initial;
    position: relative;
    max-width: 400px;
    display: initial;
    border-left: 1.5px solid ${({ theme }) => theme.colors.separator};
  }
`;

const RightSideBar = () => {
  return (
    <BarWrapper>
      <GoogleAds
        slot="2486316207"
        style={{
          display: "block",
          maxWidth: "400px",
          marginTop: "48px",
          marginBottom: "48px",
        }}
      />
    </BarWrapper>
  );
};

export default RightSideBar;
