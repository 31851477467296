import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import Text from "./Text/Text";
import logo from "../assets/images/logo.svg";

const FooterWrapper = styled.footer`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.contrast};
  background-color: ${({ theme }) => theme.colors.background};
  padding: 24px 10%;
`;

const FooterContent = styled.div`
  max-width: 1320px;
  margin: 0 auto;
`;

const FooterRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 48px;
  :last-child {
    margin-bottom: 0px;
  }
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
    & > * {
      width: 100%;
    }
  }
`;

const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 12px;
  @media (max-width: 800px) {
    margin: 24px 0;
  }
`;

const FooterTitle = styled.span`
  font-size: 16px;
  padding-bottom: 12px;
`;

const FooterText = styled.span`
  font-size: 12px;
`;

const Aside = styled.aside`
  display: flex;
  flex: 3;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const LinksWrapper = styled.nav`
  display: flex;
  flex-direction: column;
  margin: 0 16px;
  @media (max-width: 800px) {
    margin: 0;
  }
  .LinksWrapper__LinkTitle {
    font-size: 12px;
    opacity: 0.6;
  }
`;

const StyledLink = styled(Link)`
  color: inherit;
  margin: 8px 0;
`;

const AStyled = styled.a`
  color: inherit;
  margin: 8px 0;
`;

const BrandingText = styled(Link)`
  display: flex;
  align-items: center;
  width: 20%;
  color: inherit;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const FooterSocial = styled.div`
  display: flex;
  width: 20%;
  justify-content: flex-end;
  & > * {
    margin-right: 18px;
  }
  @media (max-width: 800px) {
    justify-content: flex-start;
    margin-right: auto;
    :first-child {
      margin-left: 0;
    }
  }
`;

const Copyright = styled.div`
  margin: 0 24px;
  font-size: 14px;
  @media (max-width: 800px) {
    margin: 24px 0;
  }
`;

const Column = ({ title, children }) => {
  return (
    <FooterColumn style={{ width: "30%" }}>
      <FooterTitle>{title}</FooterTitle>
      <FooterText>{children}</FooterText>
    </FooterColumn>
  );
};

const Footer = ({ content }) => {
  return (
    <Text>
      <FooterWrapper>
        <FooterContent>
          <FooterRow>
            <Aside>
              {content.aside.map((element) => (
                <Column key={element.title} title={element.title}>
                  {element.text}
                </Column>
              ))}
            </Aside>
            {content.links.map((element) => (
              <LinksWrapper key={element.title}>
                <div className="LinksWrapper__LinkTitle">{element.title}</div>
                {element.list.map((link) =>
                  link.url.charAt(0) === "/" ? (
                    <StyledLink key={link.name} to={link.url}>
                      {link.name}
                    </StyledLink>
                  ) : (
                    <AStyled key={link.name} href={link.url}>
                      {link.name}
                    </AStyled>
                  )
                )}
              </LinksWrapper>
            ))}
          </FooterRow>

          <FooterRow style={{ alignItems: "center" }}>
            <BrandingText to="/">
              <img
                src={logo}
                alt="logo"
                width="56px"
                style={{ marginRight: "12px" }}
              />
              ARK TESTED
            </BrandingText>
            <FooterColumn>
              <Copyright>{content.copyright}</Copyright>
            </FooterColumn>
            <FooterSocial>
              {content.social.map((element) => (
                <a
                  key={element.name}
                  href={element.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={element.ico} alt={element.name} width="36px" />
                </a>
              ))}
            </FooterSocial>
          </FooterRow>
        </FooterContent>
      </FooterWrapper>
    </Text>
  );
};

export default Footer;
