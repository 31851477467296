import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Img from "gatsby-image";

import Text from "./Text/Text";

const CardWrapper = styled.aside`
  flex: 0 1 auto;
  flex-basis: 100%;
  max-width: 100%;
  margin: 24px 0;
  @media (min-width: 1100px) {
    max-width: calc(50% - 40px);
    flex-basis: calc(50% - 40px);
  }
  @media (min-width: 1375px) {
    max-width: calc(33.33333% - 40px);
    flex-basis: calc(33.33333% - 40px);
  }
`;

const CardImage = styled(Img)`
  width: 100%;
  height: 200px;
  border-radius: 5px;
`;
const CardText = styled.div`
  line-height: 140%;
`;

export const CardContainer = styled.nav`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media (min-width: 700px) {
    justify-content: space-between;
  }
`;

const Card = ({ data }) => {
  return (
    <CardWrapper>
      <Link to={data.path}>
        <CardImage
          alt=""
          fluid={data.postImage.childImageSharp.fluid}
          objectFit="contain"
        />
      </Link>
      <CardText>
        <Link to={data.path}>
          <Text
            style={{ textTransform: "capitalize", margin: "12px 0" }}
            textFormat="subtitle"
          >
            {data.seoTitle}
          </Text>
        </Link>
        <Text style={{ opacity: "0.8", fontWeight: "100" }} textFormat="text">
          {data.excerpt}
        </Text>
      </CardText>
    </CardWrapper>
  );
};

export default Card;
