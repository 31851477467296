import styled from "styled-components";
import { Link } from "gatsby";

export const NavItemWrapper = styled.li`
  display: inline;
  position: relative;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  font-size: 16px;
  text-transform: capitalize;
  margin-top: -24px;
  @media (max-width: 1200px) {
    width: 110%;
  }
  :first-child {
    margin-top: 0;
  }
`;

export const Thumbnail = styled.img`
  margin-right: 15px;
  width: 14px;
  vertical-align: middle;
`;

export const Carrot = styled.button`
  float: right;
  transform: ${({ isOpen }) => (isOpen ? "rotate(-180deg)" : "rotate(0)")};
  transition: 0.3s transform;
  filter: invert(${({ theme }) => theme.filters.invert})
    grayscale(${({ theme }) => theme.filters.invert});
`;

export const SubMenu = styled.ul`
  padding: 20px 0 20px 39px;
  display: block;
  text-transform: capitalize;
  width: 100%;
  list-style: none;
  transform: ${({ isOpen }) =>
    isOpen ? "translateY(0)" : "translateY(-25px)"};
  max-height: ${({ isOpen }) => (isOpen ? "720px" : "0px")};
  opacity: ${({ isOpen }) => (isOpen ? "0.9" : "0")};
  pointer-events: ${({ isOpen }) => (isOpen ? "initial" : "none")};
  transition: transform 0.3s, max-height 0.3s, opacity 0.3s;
`;

export const SubItem = styled.li`
  margin-bottom: 12px;
`;

export const LinkStyled = styled(Link)`
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
`;
