const dark = {
  title: "dark",

  colors: {
    background: "#131417",
    backgroundSecond: "#1E1F26",
    text: "#FFF",
    contrast: "rgba(255,255,255, 0.2)",
    textHighlight: "#10B3D6",
    separator: "rgba(196, 196, 196, 0.1)",
    backgroundFocus: "#404252",
  },
  filters: {
    invert: "100%",
  },
};

export default dark;
